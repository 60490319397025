.booking-detail-title span {
	font-size: 28px;
}
.booking-detail button.MuiButtonBase-root.MuiIconButton-root {
	padding-left: 0;
}

.price_list_table {
	border-collapse: unset !important;
}

.my-patients.MuiDataGrid-root .MuiDataGrid-footerContainer .MuiDataGrid-selectedRowCount {
	visibility: hidden !important;
}

@media (max-width: 1699px) {
	.booking-detail {
		flex-wrap: wrap;
		margin-bottom: 0 !important;
	}
	.booking-detail .MuiBox-root {
		width: 100%;
		margin-bottom: 16px;
		justify-content: left;
	}
}
@media (max-width: 1366px) {
	.booking-detail-scanner img {
		max-height: 100px;
	}
	.booking-detail-scanner a {
		display: block;
		text-align: center;
	}
}
@media (max-width: 1024px) {
	.dent-table-search-input {
		display: none !important;
	}
}

@media (max-width: 991px) {
	.booking-detail-title span {
		font-size: 24px;
	}
	.booking-detail-scanner p {
		font-size: 16px;
		padding-bottom: 0;
	}
}

@media (max-width: 767px) {
	.booking-detail .MuiButton-label {
		font-size: 0;
	}
	.booking-detail .MuiButton-root {
		letter-spacing: normal;
	}
	.booking-detail .MuiButton-startIcon {
		margin-right: 0;
		margin-left: 0;
	}
	.booking-detail-title span {
		font-size: 20px;
	}
	.booking-detail-form h5 {
		font-size: 20px;
	}
	.booking-form-title {
		margin-bottom: 10px;
	}
	.my-patients {
		max-width: 100% !important;
	}
	.my-patients .MuiDataGrid-windowContainer {
		height: auto !important;
	}
	.my-patients.MuiDataGrid-root .MuiDataGrid-window {
		position: unset;
	}
	.my-patients span.table-status-clm {
		/* max-width: 90px !important; */
		display: inline-block !important;
		padding: 5px !important;
		line-height: normal !important;
		min-width: unset !important;
	}
	.my-patients .MuiDataGrid-cell.MuiDataGrid-cell.MuiDataGrid-cell {
		flex-direction: column;
		padding: 5px 0px !important;
	}
	.my-patients .MuiDataGrid-cell {
		padding: 0px !important;
	}
	.my-patients .MuiDataGrid-cell > * {
		padding: 10px 15px !important;
	}
	.my-patients .MuiDataGrid-cell > *:last-child {
		border-bottom: unset !important;
	}
	.my-patients .MuiDataGrid-window * {
		height: 100% !important;
		min-height: unset !important;
		max-height: unset !important;
		width: 100%;
		line-height: normal !important;
	}
	.my-patients .MuiDataGrid-window .dentq-mobile-patient-row-status {
		display: flex;
		width: 100% !important;
		justify-content: flex-end;
		align-items: center !important;
	}
	.my-patients .MuiDataGrid-cell.MuiDataGrid-cell.MuiDataGrid-cell {
		background: #fff !important;
	}
	.my-patients .MuiDataGrid-window .dentq-mobile-patient-row-status p {
		width: unset !important;
	}
	.my-patients .MuiDataGrid-window img {
		width: 20px;
		margin-right: 5px;
	}
	.my-patients
		.MuiDataGrid-renderingZone
		> div:nth-child(even)
		> .MuiDataGrid-cell.MuiDataGrid-cell.MuiDataGrid-cell {
		background: #f9f9f9 !important;
	}
	.my-patients .MuiDataGrid-root {
		max-width: 100%;
	}
	.my-patients.MuiDataGrid-root .MuiDataGrid-row {
		width: 100% !important;
		margin-bottom: 10px !important;
	}
	.my-patients .MuiDataGrid-cell {
		flex-direction: column;
		padding: 10px 0 !important;
		background: #f5f5f5;
		border-radius: 8px;
		overflow: hidden;
		border: 1px solid #e3e3e3;
		margin: 2px !important;
	}
	.my-patients .MuiDataGrid-cell.MuiDataGrid-cell.MuiDataGrid-cell {
		flex-direction: column;
		margin: 0px 3px !important;
		max-width: calc(100% - 6px) !important;
		min-width: unset !important;
	}
	.parent-image.model-img {
		min-height: 320px !important;
	}
	/* .popup-model .MuiDialog-paperWidthLg {
		height: auto !important;
	} */
	/* .price_list_table {
		height: 100%;
		max-height: 350px;
		overflow: auto;
	}
	.price_list_table thead.MuiTableHead-root:first-child {
		position: sticky;
	} */
}
@media (max-width: 575px) {
	.booking-detail-title span {
		font-size: 18px;
	}
	.booking-detail-scanner img {
		max-height: 70px;
	}
	.booking-detail-form h5 {
		font-size: 18px;
	}
	.booking-section h5 {
		font-size: 20px;
	}
	.booking-date-drp .MuiGrid-root {
		width: 100%;
	}
	.booking-date-drp .MuiFormControl-root {
		width: 100%;
	}
	.report-price-data h6 {
		font-size: 14px;
	}
}
