.App {
	text-align: center;
}

/**
full-calendar css
 */
.fc-col-header {
	background: #ffffff;
}
.dentq-full-calender > div {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	height: 68px;
	width: 100%;
}
.fc-timegrid {
	background: #ffffff;
	box-shadow: 0px 0px 16px rgba(99, 103, 106, 0.16);
}
.fc-timegrid-body tr {
	height: 44px !important;
}
.fc-timegrid-body tr:nth-child(even) {
	background-color: #fbfbfb;
	opacity: 0.5;
}

.fc-scroller {
	height: 100% !important;
}

// * Header
.calender-heder-cell {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.calender-heder-cell .day {
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	letter-spacing: 0.02em;
}
.calender-heder-cell .date {
	font-size: 20px;
	font-weight: 300;
	line-height: 21px;
	letter-spacing: 0.02em;
}

//event cell
.fc-event {
	background: transparent !important;
	border: transparent !important;
	border-radius: 8px !important;
}
.fc-event-main {
	display: flex;
	align-items: center;
	justify-content: center;
}
