/* eslint-disable */

@font-face {
	font-family: 'Script2Italic';
	src: url(../fonts/anything-script-2-italic.woff) format('woff');
}

.spinner-cls {
	display: 'block';
	transform: scale(2.5);
	position: 'relative';
	opacity: 0.5;
}

.calenderPage .makeStyles-content-2 {
	min-height: calc(100vh - 256px) !important;
	max-height: calc(100vh - 256px) !important;
}

body.calenderPage {
	max-height: 100vh;
	overflow: hidden;
}

body .MuiGridMenu-root ul li:last-child {
	display: none;
}

body .MuiGridMenu-root ul li:nth-last-child(2) {
	display: none;
}

.MuiDataGrid-root .MuiDataGrid-overlay{
	top: 100px !important;
	align-items: unset !important;
}

.MuiIconButton-colorPrimary.gray_btn {
	color: #63676a;
}

.MuiIconButton-colorPrimary.gray_btn:hover {
	color: #d0df00;
}

.left_menu_ul > a.MuiListItem-gutters {
	padding-right: 10px;
	color: #fff;
}

.left_menu_ul > a.Mui-selected {
	background: #4c4c4c;
	color: #fff;
}

/* .left_menu_ul > a.Mui-selected .MuiListItemIcon-root {
	color: #fff;
} */

.left_menu_ul > a .MuiListItemIcon-root {
	min-width: 46px;
}

.left_menu_ul > a.MuiListItem-button:hover {
	text-decoration: none;
	background-color: #4c4c4c;
	color: #fff;
}

.makeStyles-grid-34 .theme-header {
	background: #63676a !important;
	color: #fff !important;
	font-size: 16px;
}

.MuiDataGrid-root
	.MuiDataGrid-columnHeaderWrapper
	> div
	.MuiDataGrid-columnSeparator {
	display: none;
}

.MuiIconButton-colorPrimary.gray_btn:first-child {
	padding-left: 0px;
}

.MuiBreadcrumbs-li .MuiTypography-colorTextPrimary {
	color: #c8da2e;
	font-size: 32px;
}

.left_side_menu ~ div > main {
	padding: 40px !important;
}

.popup_box .MuiDialogTitle-root {
	padding: 30px 20px !important;
}

.popup_box .MuiDialog-paper {
	min-width: 300px;
	min-height: 170px;
	position: relative;
}

.data-grid-sub-filter .MuiSelect-root{
	font-size: 14px;
	color: rgba(133, 133, 133) !important;
}

.data-grid-sub-filter .MuiSvgIcon-root{
	color: rgba(133, 133, 133);
}

.data-grid-sub-filter .MuiOutlinedInput-notchedOutline{
	border: none;
}
.data-grid-sub-filter .MuiOutlinedInput-inputMarginDense{
	padding-top: 6.5px;
	padding-bottom: 6.5px;
}

.popup_box .MuiDialogTitle-root .MuiButtonBase-root {
	position: absolute;
	right: 0;
	top: 0;
	padding: 5px;
	background: #ccc;
	border-radius: 0;
}

.popup_box .MuiDialog-paperScrollPaper {
	justify-content: space-between;
}

.popup_box h2 {
	text-align: center;
}

.partner-header-padding .MuiDataGrid-columnsContainer{
	padding-bottom: 30px;
}

.daterangepicker .drp-buttons .btn{
	padding: 6px 8px !important;
}

.daterangepicker .cancelBtn, .daterangepicker .applyBtn{
	border: none;
}

.daterangepicker td.in-range{
	background-color: #ebedd3 !important;
	color: #63676a !important;
}

.daterangepicker td.active, .daterangepicker td.active:hover{
	background-color: #d0df00 !important;
	color: #63676a !important;
}

.MuiDialogTitle-root ~ .MuiButtonBase-root.MuiIconButton-root {
	position: absolute;
	right: 0;
	top: 0;
	padding: 5px;
	background: #ccc;
	border-radius: 0;
}

.fc-bg-event {
	cursor: pointer;
}

header {
	box-shadow: none !important;
}

header .desk-view-account-cls,
header .mobile-view-account-cls {
	margin-left: 25px;
}

header .desk-view-account-cls .MuiBox-root,
header .mobile-view-account-cls .MuiBox-root {
	color: #63676a !important;
	font-size: 16px !important;
	padding: 0 15px;
	margin-top: 13px;
	margin-top: 0px;
	display: flex;
	align-items: center;
	margin: 0px;
}

/* .left_side_menu > div {
	margin-top: 80px;
} */

.left_side_menu ~ div {
	margin-top: 15px;
}

.left_menu_ul {
	padding-top: 0px !important;
}

.search-box {
	box-shadow: none !important;
	margin-bottom: 17px;
}

.search-box .search-box_inr {
	padding-top: 0px;
	padding-left: 0;
}

.search_title {
	color: #63676a;
	font-size: 20px !important;
	font-weight: 500 !important;
}

.search-box form label {
	color: #63676a;
	font-size: 18px !important;
	font-weight: 500 !important;
}

.search_form {
	margin-left: 80px !important;
}

.date_field {
	background-position: calc(100% - -5px) 13px;
	background-position: 100% 18px;
	background-size: 41px;
}

.search_btn {
	align-items: flex-end !important;
	padding-bottom: 7px !important;
}

.search_btn > button {
	width: 98px;
	height: 40px;
	box-shadow: none;
}

.search_btn > button span {
	color: #63676a;
	text-transform: capitalize;
	font-size: 16px !important;
}

.search_form input {
	padding: 12px 0;
	color: #63676a;
	font-size: 16px;
}

.left_side_menu ~ div {
	margin-top: 15px;
	min-height: calc(100vh - 80px);
}

.left_side_menu ~ div > main {
	min-height: calc(100vh - 275px);
	padding-bottom: 0px !important;
}

/* .appointmentScan_tbl {
	height: 400px !important;
} */

.left_side_menu ~ div.appointmentScan_main > main {
	min-height: calc(100vh - 311px);
}

.left_side_menu ~ div.appointmentScan_main .makeStyles-grid-40 {
	min-height: calc(100vh - 556px);
}

/* .search-box ~ div {
	height: calc(100vh - 510px) !important;
	height: calc(100vh - 425px) !important;
} */

.appointmentScan_main main > nav ~ div {
	height: calc(100vh - 305px);
	/* height: calc(100vh - 315px); */
	/* height: calc(100vh - 385px); */
	overflow: auto;
}

.calenderPage .appointmentScan_main main > nav ~ div {
	height: auto;
}

.areawise-dropdown {
	padding: 20px 7px;
	padding-right: 10px;
}

.areawise-dropdown > div {
	display: flex;
}

.areawise-dropdown button svg {
	opacity: 0;
}

.areawise-dropdown button {
	background-repeat: no-repeat !important;
	background-position: center !important;
	background-size: auto 65% !important;
}

.areawise-dropdown button {
	background-image: url(../images/location.png);
	background-size: 17px !important;
}

img.frontLogo {
	margin-bottom: 30px;
}

img.frontLogo + .MuiAvatar-circular {
	background-color: #d0df00;
}

.frontLogo + .MuiGrid-container a {
	display: block;
	text-align: left;
}

.left_side_menu .areawise-dropdown .MuiFormControl-root {
	min-width: unset;
	width: 100%;
	text-align: left;
	padding-left: 15px;
	padding-top: 8px;
}

.left_side_menu .areawise-dropdown .MuiFormControl-root .MuiSelect-root {
	padding-bottom: 12px;
	background-image: url(../images/downarrow.png);
	background-position: 150px 4px;
	background-repeat: no-repeat;
}

.areawise-dropdown .MuiFormControl-root svg {
	display: none;
}

.areawise-dropdown button:hover,
.areawise-dropdown .MuiSelect-select:focus {
	background-color: transparent;
}

/* .appointmentScan_tbl .theme-header {
	background-color: #fff !important;
	color: #000;
} */

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
	display: none;
	-webkit-appearance: none;
}

.doctor-header-cls header .MuiBox-root {
	font-size: 16px;
	font-weight: 400;
	line-height: 21px;
	color: #63676a;
}

/* .doctor-header-cls
	header
	.MuiIconButton-root[aria-label='account of current user']
	.MuiIconButton-label {
	background-image: url(../images/question.png);
	background-repeat: no-repeat;
} */

.doctor-header-cls header .MuiIconButton-root[aria-label='help'] {
	padding: 6px;
	max-width: 34px;
	margin-right: 6px;
}

.doctor-header-cls
	header
	.MuiIconButton-root[aria-label='account of current user'] {
	padding: 7px 10px;
	margin-right: 0px;
}

/* .doctor-header-cls
	header
	.MuiIconButton-root[aria-label='help']
	.MuiIconButton-label {
	background-image: url(../images/downarrow.png);
	background-repeat: no-repeat;
	background-position: center 0;
} */

.doctor-header-cls header .MuiButtonBase-root + div.MuiBox-root {
	margin-right: 27px;
	margin-top: 7px;
}

.desk-view-account-cls {
	display: flex;
	align-items: center;
}

.doctor-header-cls header .MuiIconButton-root[aria-label='help'] {
	max-height: 34px;
}

.doctor-header-cls header a img {
	margin-left: 0 !important;
	margin-right: 10px !important;
}

.doctor-header-cls header a {
	display: inline-block;
}

.doctor-header-cls
	header
	.MuiIconButton-root[aria-label='account of current user']
	+ div.MuiBox-root {
	padding: 0 10px 0 20px !important;
}

.search_form form label {
	font-weight: bold !important;
	font-size: 16px !important;
}

#dateOfBirth[type='date'] {
	color: #63676a;
}

.MuiTablePagination-root .MuiToolbar-gutters {
	padding-left: 0px;
}

.select-menu label {
	background: #fafafa;
	padding: 0 10px;
	position: relative;
	top: 7px;
	z-index: 1;
	left: 8px;
	float: left;
}

.select-menu .MuiSelect-selectMenu {
	text-align: left;
}

/* .select-menu .MuiSelect-select:focus{ background: #fff;} */

/* .doctor-header-cls .frontLogo{ height: 50px; }
.doctor-header-cls > main > div:first-child > div{ margin-top: 33px; } */

.MuiMenu-list.MuiList-padding option {
	padding: 10px 10px;
	cursor: pointer;
}

.MuiMenu-list.MuiList-padding option:hover {
	background: #f2f2f2;
}

.check-form-control label span.MuiFormControlLabel-label {
	text-align: left;
}

.MuiSelect-select.MuiSelect-selectMenu {
	text-align: left !important;
}

.password-rule-cls {
	width: 100%;
	padding: 8px 8px;
}

.password-rule-cls ul {
	padding-left: 5px;
	margin: 0px;
}

.password-rule-cls ul li {
	list-style: none;
	text-align: left;
	position: relative;
	padding-left: 24px;
	/* background: #ccc; */
	margin-bottom: 5px;
	color: #666;
}

.password-rule-cls ul li::before {
	position: absolute;
	left: 0;
	top: -4px;
	bottom: 0;
	margin: auto;
	content: '';
	font-size: 20px;
	font-weight: bold;
}

.password-rule-cls ul li.cross-cls:before {
	content: '\00d7';
	color: red;
}

.password-rule-cls ul li.right-cls:before {
	content: '\2713';
	color: green;
}

.password-rule-cls label {
	width: 100%;
	display: inline-block;
	text-align: left;
	margin-bottom: 10px;
}

.has-account-link-cls > div {
	width: 100%;
	margin-top: 15px;
}

.has-account-link-cls a {
	text-align: center;
	width: 100%;
	display: inline-block;
	font-size: 16px;
}

.signup-cls-div .MuiAvatar-circular {
	background-color: #d0df00;
}

.full-box {
	/* background-color: #ccc; */
	display: flex;
	justify-content: space-between;
	padding: 0 7px;
}

.full-box .half-box {
	width: calc(50% - 15px);
	flex-basis: calc(50% - 15px);
}

.input-box {
	display: flex;
	align-items: center;
	margin-bottom: 15px;
}

.input-box-title {
	white-space: nowrap;
	margin-right: 15px;
	min-width: 210px;
	text-align: left;
}

.text-left {
	text-align: left;
}

.top_box_title {
	text-align: left;
	border-bottom: 1px solid #ccc;
	padding-bottom: 10px;
	margin-bottom: 20px;
	margin-top: 8px;
}

.checkbox-box {
	display: flex;
	align-items: center;
	flex-direction: row-reverse;
	margin-bottom: 15px;
}

.checkbox-box .input-box-title {
	width: 100%;
}

.checkbox-box > div {
	width: 50px;
}

.shadow-box {
	box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 12%);
	border-radius: 5px;
	padding: 15px;
	margin-bottom: 15px;
}

.mt-0 {
	margin-top: 0px !important;
}

.checkbox-box label {
	width: 100%;
	display: flex;
	flex-direction: row-reverse;
	margin: 0;
}

.checkbox-box label span.MuiIconButton-root {
	margin: 0px;
	padding-right: 0px;
}

.checkbox-box label span.MuiFormControlLabel-label {
	width: 100%;
	text-align: left;
	font-size: 14px;
}

.multi_input {
	width: 100%;
}

.multi_input > div {
	margin-bottom: 15px;
}

.flex_start {
	align-items: flex-start;
}

.input-box.flex_start .input-box-title {
	margin-top: 18px;
}

.submit_box {
	padding-top: 50px;
	padding-bottom: 50px;
}

.submit_box > button {
	max-width: 200px;
}

.ex-date {
	display: flex;
	align-items: center;
}

.slash_box {
	font-size: 30px;
	padding: 0 30px;
	color: #ccc;
}

.checkbox-box.checkbox-box-icon label {
	flex-direction: row;
}

.checkbox-box.checkbox-box-icon label span.MuiIconButton-root {
	padding-right: 9px;
	padding-left: 0px;
}

.checkbox-box.checkbox-box-icon label span.MuiFormControlLabel-label {
	background: url(../images/vc.png) no-repeat;
	background-position: left center;
	background-size: auto 100%;
	padding: 5px 0 5px 58px;
}

.w_100 {
	width: 100%;
}

.success_msg_box {
	min-height: calc(100vh - 250px);
	display: flex;
	align-items: center;
	justify-content: center;
}

.success_msg h1 span.green-box {
	white-space: nowrap;
	padding: 30px;
	color: green;
	background: #00800020;
	border-radius: 3px;
	box-shadow: 0 0 2px 2px #00800050;
}

.success_msg h1 span.red-box {
	white-space: nowrap;
	padding: 30px;
	border-radius: 3px;
	color: red;
	background: #f0000030;
	box-shadow: 0 0 2px 2px #f0000050;
}

a.back_btn {
	color: rgba(0, 0, 0, 0.87);
	background-color: #d0df00;
	box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
		0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
	padding: 5px 22px;
	font-size: 20px;
	min-width: 64px;
	box-sizing: border-box;
	transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
		box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
		border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
	font-weight: 500;
	line-height: 1.75;
	border-radius: 4px;
	letter-spacing: 0.02857em;
	text-decoration: unset;
	margin-top: 26px;
	display: inline-block;
}

.thankyou_box {
	position: relative;
	padding-top: 60px;
}

.thankyou_box::before {
	position: absolute;
	left: 0;
	content: '\2713';
	right: 0;
	font-size: 40px;
	color: green;
	font-weight: bold;
	background: #00800020;
	width: 60px;
	height: 60px;
	text-align: center;
	border-radius: 100%;
	margin: auto;
	top: 0;
	padding-top: 2px;
}

.thankyou_box h1 {
	font-size: 50px;
	margin-top: 0px;
	color: green;
}

.thankyou_box p {
	font-weight: bold;
	color: #333;
}

.search-box input {
	border: 1px solid;
	padding-left: 10px;
}

.search-box {
	display: flex;
	justify-content: flex-end;
}

.w_100 {
	width: 100%;
}

.branches-list {
	display: flex;
	flex-wrap: wrap;
}

.branch_box {
	width: calc(25% - 3px);
	margin-bottom: 10px;
	cursor: pointer;
}

.branch_box a {
	display: inline-block;
	width: 100%;
}

.branch_box a img {
	width: 100%;
}

.popup-outer-box {
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(0, 0, 0, 0.5);
	z-index: 111111;
}

.branch-form-popup {
	background: #fff;
	width: 100%;
	max-width: 500px;
	padding: 20px;
	min-height: 300px;
	max-height: 90vh;
	overflow: auto;
}

.flex_row {
	display: flex;
}

.search-box .search-box_inr {
	justify-content: space-between;
	align-items: flex-end;
	width: 100%;
}

.search-box .search-box_inr .flex_row input {
	border: 0;
}

.doctorProfile-cls .appointmentScan_main main > nav ~ div {
	height: auto;
}

.flex_row_end {
	display: flex;
	align-items: flex-end;
}

.filter_search h6 {
	width: 100%;
	text-align: left;
}

.filter_search button.MuiButton-text .MuiButton-label {
	color: #63676a;
	text-transform: capitalize;
	font-size: 16px !important;
	padding-top: 20px;
}

.filter_search button.MuiButton-text {
	padding-bottom: 0;
	line-height: 15px;
}

.filter_search button.MuiButton-text .MuiButton-label {
	font-size: 0 !important;
	padding: 0;
	position: relative;
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
	width: 13px;
	height: 15px;
	top: -10px;
}

.filter_search button.MuiButton-text .MuiButton-label + .MuiTouchRipple-root {
	top: -12px;
	left: 20px;
	width: 25px;
	height: 25px;
}

.filter_search button.MuiButton-text .MuiButton-label::before {
	content: 'X';
	position: absolute;
	font-size: 20px;
	color: #000;
	top: 0px;
}
.filter_search {
	position: relative;
	padding-right: 30px;
	overflow: hidden;
}
.filter_search > div:nth-child(2) {
	right: -20px;
	position: absolute;
}

.title-your-case,
.title-other-case {
	color: #63676a;
	font-size: 20px !important;
	font-weight: 500 !important;
	text-align: left;
	margin-top: 20px !important;
}

.active-filter-btn {
	background: #ccc !important;
}

.order-form-spacing {
	margin-top: 10px !important;
	margin-bottom: 10px !important;
}

/* //TODO: Select style remove after proper label solution */
.MuiFormControl-root .MuiInputLabel-outlined.MuiInputLabel-shrink {
	background: #fff;
}
.MuiFormControl-root .MuiFormLabel-root.Mui-focused {
	background: transparent !important;
}

/* * data grid CSS */
.MuiDataGrid-root {
	border: none !important;
}

.MuiDataGrid-columnHeader {
	font-size: 16px !important;
	font-style: normal !important;
	font-weight: 500 !important;
	letter-spacing: 0.02em !important;
	background: #fff !important;
}

.MuiDataGrid-cell {
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 21px;
	letter-spacing: 0em;
}

.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
	outline: none !important;
}
.MuiDataGrid-columnHeaderTitleContainer {
	padding: 0px !important;
}

.MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-cell:focus-within {
	outline: none !important;
}

.my-patients .MuiDataGrid-row {
	cursor: pointer;
}

@media (max-width: 767px) {
	.my-patients .MuiDataGrid-columnsContainer {
		display: none !important;
	}
	.MuiDataGrid-root .MuiDataGrid-cell {
		border: 1px solid gray;
		padding-bottom: 15px;
	}
	.MuiDataGrid-root .MuiDataGrid-cell .MuiBox-root {
		padding: 5px;
	}
}

/* * navbar CSS */
.MuiToolbar-regular {
	min-height: 78px !important;
}

/* * Disabled appointment */
.appointment-disabled {
	opacity: 0.7;
	position: relative;
}

.appointment-disabled::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}

/* //* MUI tree view */
.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label,
.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover,
.MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label {
	background-color: unset !important;
}

.refer-checkbox-tooltip {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}
.refer-checkbox-tooltip img {
	max-width: 200px;
}
.field_helper_link {
	color: #008aaf;
	cursor: pointer;
}

.sign-up-option-group-lbl {
	display: inline-block;
	font-weight: 500 !important;
	padding: 10px;
	font-size: 16px !important;
	background-color: rgb(204, 204, 204) !important;
	width: 100%;
	color: #fff !important;
	line-height: 22.88px !important;
}

.global-input-width {
	max-width: 300px !important;
}
.global-textarea-width {
	max-width: 680px !important;
}
.global-input-margin-left {
	margin-left: 80px !important;
}

/* //* font CSS */
.text-transform-none {
	text-transform: none !important;
}

.appointment-height .fc .fc-scrollgrid-liquid,
.appointment-height .fc .fc-view-harness {
	height: 600px !important;
}

.appointment-select-date-modal .fc {
	.fc-view-harness {
		height: calc(100vh - 255px) !important;
		@media (min-width: 575px) and (max-width: 1024px) {
			.cadcamBlocked {
				width: 40px;
			}		
		}
	}
	@media (max-width: 575px) {
		.fc-toolbar-title {
			font-size: 1.2rem;
		}		
	}
}

@media (max-width: 767px) {
	.fc-view-harness{
		height: calc(100vh - 360px) !important;
	}
}


@media (max-width: 1366px) {
	.input-box-title {
		white-space: normal;
		min-width: 150px;
	}
	.branch_box {
		width: calc(33.33% - 3px);
		margin-bottom: 10px;
	}
}

@media (max-width: 1224px) {
	/* .left_menu_ul > a.MuiListItem-gutters {
		padding-left: 55px;
	} */
	.search-box .search-box_inr {
		flex-direction: row;
		/* flex-wrap: wrap; */
	}
	.search_form {
		margin-left: 20px !important;
		display: flex;
		flex-direction: column;
	}
	.search_form form {
		padding-right: 10px;
	}
	.search_form form > div {
		width: 100% !important;
		margin-left: 0px !important;
	}
	header button {
		margin-right: 0px !important;
	}
	.left_side_menu ~ div > main {
		padding: 40px 20px !important;
	}
	.search-box_inr h6.search_title {
		/* width: 100%; */
		text-align: left;
		margin-right: 20px;
	}
	.search_form form div:first-child {
		margin-left: 0;
	}
	.desk-view-account-cls > .MuiBox-root ~ button span svg {
		height: 24px;
	}
	.appointmentScan_main main > nav ~ div {
		height: auto;
	}
}

@media (max-width: 1024px) {
	.teeth-model-iframe {
		width: 100%;
	}
}

@media (max-width: 991px) {
	.search_form {
		margin-left: 0px !important;
	}
	.search-box .search-box_inr {
		padding-left: 0px !important;
	}
}

@media (max-width: 959px) {
	.doctor-header-cls
		header
		.MuiIconButton-root[aria-label='account of current user']
		span {
		height: 22px;
		min-width: 22px;
	}
}

@media (max-width: 767px) {
	.search_btn {
		margin-left: 0px !important;
		width: 100%;
		margin-bottom: 8px;
		/* flex-direction: column;
        align-items: flex-start !important;
        justify-content: flex-end; */
	}
	/* .search_btn > div{ margin-left: 0px; } */
	.search_form {
		margin-bottom: 25px;
	}
	/* .appointmentScan_main main>nav~div {
        height: auto;
    } */
	.doctor-header-cls
		header
		.MuiIconButton-root[aria-label='account of current user'] {
		padding: 0px 0px;
		max-width: 50px;
		display: block;
		margin-top: 0px;
	}
	.doctor-header-cls
		header
		.MuiIconButton-root[aria-label='account of current user']
		.MuiIconButton-label {
		background-position: center;
	}
	.left_side_menu ~ div.common-datagrid-cls > main {
		margin-top: 30px;
	}
	.flex_row {
		flex-direction: column;
		width: 100%;
	}
	.search-box .search-box_inr {
		flex-direction: column;
	}
	.search-box .search-box_inr > div,
	.search-box .search-box_inr > div:last-child * {
		width: 100%;
	}
	.global-input-width {
		max-width: none !important;
	}
	.global-textarea-width {
		max-width: none !important;
	}
	.global-input-margin-left {
		margin-left: unset !important;
	}
}

@media (max-width: 575px) {
	.mobile-view-account-cls > div {
		display: block;
		cursor: pointer;
	}
	.common-datagrid-cls main > nav ~ div {
		height: 100%;
	}
	.doctor-header-cls header + .MuiToolbar-regular {
		min-height: auto;
	}
	.common-datagrid-cls {
		margin-top: 0;
		min-height: auto;
	}
	.doctor-header-cls header > .MuiToolbar-regular {
		padding-left: 33px;
	}
	.common-datagrid-cls ol li h5 {
		font-size: 25px !important;
		line-height: 29px;
	}
	.left_side_menu ~ div.common-datagrid-cls > main {
		margin-top: 80px;
	}
	.doctor-header-cls header img {
		height: 45px !important;
		margin-left: 0px !important;
		padding-left: 10px !important;
	}
	header button svg.MuiSvgIcon-root {
		width: 30px;
	}
	.doctor-header-cls header .MuiButtonBase-root + div.MuiBox-root {
		margin-right: 7px;
	}
	.date_field {
		margin-top: 30px;
	}
}

@media (max-width: 551px) {
	.left_side_menu ~ div > main .MuiBox-root .MuiTextField-root {
		width: 100% !important;
		margin: 15px 0;
	}
	.left_side_menu ~ div > main {
		padding: 20px !important;
		background: #fff;
	}
	.left_side_menu ~ div > main .MuiBox-root {
		width: 100%;
		margin-left: 0px;
	}
	header .desk-view-account-cls .MuiBox-root,
	header .mobile-view-account-cls .MuiBox-root {
		padding: 0 5px;
	}
	.doctor-header-cls header .MuiIconButton-root {
		padding: 12px 0px;
		padding: 0px 7px;
	}
	.doctor-header-cls
		header
		.MuiIconButton-root[aria-label='account of current user'] {
		margin-left: 10px;
	}
}

@media (max-width: 399px) {
	.mobile-view-account-cls {
		margin-left: 0px !important;
	}
}
